<template>
  <b-container fluid>
    <b-row class="justify-content-center wrapper-row" align-v="center">
      <b-col sm="auto" class="forgot-password">
        <b-card class="trial-wrapper text-center">
          <h4 slot="header">Forgot Password</h4><section id="firebaseui-auth-container" />
      <b-form @submit.prevent>
        <b-form-group label="Email" label-for="input-email" class="mb-3">
            <b-form-input
                id="email"
                v-model="email"
                name="email"
                required
                v-on:keyup="disablingMsg"
                placeholder="Ex : diviiuser@google.com"
                data-testid="forgot-password-email"
            />
        </b-form-group>
        <b-button
            variant="primary"
            block
            :disabled="!canSubmit || isResentLinkLoader"
            type="submit"
            @click="sendEmail"
            data-testid="forgot-password-send-email-button"
            
        > 
        <template v-if="isResentLinkLoader">
										<b-spinner small type="grow"></b-spinner>
										Sending...
									</template>
									<template v-else>
										Submit
									</template>
        </b-button>
        <div v-if="emailSending" class="success-msg">Reset link has been sent to the mail <b>{{email}} </b>
          .If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder.</div>
        <div v-else class="error">{{ error }}</div>
          
          <div class="back-to-login"><b-link @click="$router.push({ name: 'login' })" data-testid="back-to-login-link">
							<b-icon-arrow-left-circle class="arrow-icon" data-testid="back-to-login-icon"></b-icon-arrow-left-circle>Back to Login
						</b-link></div>
        </b-form>
       </b-card>
      </b-col>
    </b-row>
  </b-container>
  </template>
  
  <script>

  import { auth } from '../firebase';
  import { BIconArrowLeftCircle } from 'bootstrap-vue';
  import isEmail from 'validator/lib/isEmail';

  function email(value) {
	return value && isEmail(value.trim());
}
  export default {
    name:'ForgotPassword',
    components: {
      BIconArrowLeftCircle,
	},
    data() {
      return {
        email: "",
        error: null,
        emailSending: false,
        msg: "",
        isResentLinkLoader: false,
      };
    },
    computed:{
      canSubmit(){
        return email(this.email) != '';
      }
    },
    methods: {
      sendEmail() {
        if (!this.email) {
          this.error = "Please type in a valid email address.";
          return;
        }
        this.error = null;
        this.isResentLinkLoader = true;
        auth
          .sendPasswordResetEmail(this.email)
          .then(() => {
            this.isResentLinkLoader = false;
            this.emailSending = true;
            console.log("Reset password link sent successfully")
          })
          .catch(error => {
            this.isResentLinkLoader = false;
            this.emailSending = false;
            this.error = error.message;
            console.error("ERROR:", error.message)
          });
      },
      disablingMsg(){
        this.emailSending = false;
        this.error='';
      },
    },
  };
  </script>

  <style>
  .forgot-password{
    width: 400px;
  }
  .trail-wrapper{
    height: 280px;
  }
  .error{
    color: red;
    padding-top: 10px;
  }
  .success-msg{
    color: green;
    padding-top: 10px;
  }
  .back-to-login{
    padding-top: 10px;
  }
  .arrow-icon{
    padding-right: 3px;
  }
  </style>